@import '../../styles/theme.scss';
@import '../../styles/shared.scss';

.view-icon-circle .thumbs {
    color: white;
    font-size: 30px;
    height: 48px;
    left: 8px;
    position: relative;
    top: 22px;
    width: 48px;
    z-index: 2;
}

.view-icon-circle .star {
    color: $secondary-color;
    font-size: 14px;
    height: 18px;
    width: 18px;
    z-index: 2;
}

.star.left {
    left: 8px;
    position: relative;
    top: -38px;
}

.star.middle {
    left: 4px;
    position: relative;
    top: -46px;
}

.star.right {
    left: 4px;
    position: relative;
    top: -38px;
}

.text-primary.ant-typography {
    color: $primary-color;
}

.submitted-paragraph {
    margin-top: 1.5rem;
}

.submitted-noline.ant-typography {
    margin: 0;
}

.submitted-telephone-container {
    margin: auto;
    max-width: 300px;
}

.submitted-email-container {
    margin: auto;
    max-width: 192px;
}

.text-sgi-green {
    color: $sgi-green-color;
}
