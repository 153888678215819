$small: 300px;
$medium: 900px;

$primary-color: rgba(28, 58, 105, 1);
$secondary-color: rgba(235, 105, 16, 1);
$primary-color-op2: rgba(28, 58, 105, 0.2);
$primary-color-op5: rgba(28, 58, 105, 0.5);
$primary-color-op8: rgba(28, 58, 105, 0.8);
$sgi-green-color: rgba(0, 74, 44, 1);
$secondary-color-light: rgba(230, 230, 230, 1);
$light-background: #e7eff0;
$grey-color: rgba(128, 128, 128, 1);
