@import '../../styles/theme.scss';
@import '../../styles/shared.scss';

.view-icon-circle .cross {
    color: white;
    font-size: 48px;
    height: 64px;
    position: relative;
    top: 8px;
    width: 64px;
    z-index: 2;
}
