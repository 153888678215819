@import './theme.scss';

.flex-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.view-card {
    margin-bottom: 12px;
    max-width: 600px;
    min-width: 360px;
}

.view-icon-circle {
    background-color: $primary-color;
    border-radius: 64px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -48px;
    width: 64px;
    z-index: 1;
}

.bold,
.bold.ant-typography {
    font-weight: 700;
}

.extrabold {
    font-weight: 900;
}

.small-text {
    font-size: 10px;
}

.text-centered {
    text-align: center;
}

.text-right-aligned {
    text-align: right;
}

.flex-container > button {
    height: auto;
    padding: 12px;
    position: relative;
    top: 48px;
    width: auto;
}

.flex-container > button > div > span {
    vertical-align: middle;
}

.flex-container > button > div > span + span {
    font-size: 18px;
    margin-left: 8px;
}
