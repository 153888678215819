@import '../../styles/theme.scss';
@import '../../styles/shared.scss';

.view-icon-circle .outer-shield {
    color: white;
    font-size: 38px;
    height: 48px;
    left: 8px;
    position: relative;
    top: 14px;
    width: 48px;
    z-index: 2;
}

.view-icon-circle .line-shield {
    color: $primary-color;
    font-size: 34px;
    height: 48px;
    left: 8px;
    position: relative;
    top: -32px;
    width: 48px;
    z-index: 3;
}

.view-icon-circle .inner-shield {
    color: white;
    font-size: 30px;
    height: 48px;
    left: 8px;
    position: relative;
    top: -78px;
    width: 48px;
    z-index: 3;
}

.view-icon-circle .shield-check {
    color: $secondary-color;
    font-size: 48px;
    height: 48px;
    left: 8px;
    position: relative;
    top: -132px;
    width: 48px;
    z-index: 3;
}

.userform-splitcontainer {
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    min-width: calc(360px + 4%);
    padding: 0 2%;
    width: 46%;

    @media screen and (max-width: $medium) {
        justify-content: center;
        margin-top: 32px;
        width: 96%;
    }
}

.userform-sepa {
    font-size: 20px;
}

.userform-datacontainer {
    background-color: rgb(229, 229, 229);
    color: rgb(112, 112, 112);
    flex-grow: 1;
    padding: 48px;

    @media screen and (max-width: $medium) {
        justify-content: center;
        margin-top: 32px;
        width: 96%;
    }
}

.userform-datacontainer:nth-child(even) {
    align-self: end;
    margin-bottom: 16px;
}

.userform-datacontainer h4,
.userform-datacontainer-text {
    color: rgb(112, 112, 112);
}

.userform-datacontainer .row-gap {
    margin-top: 24px;
}

.userform-divider {
    border-color: rgb(112, 112, 112);
    margin: 4px 0;
}

.userform-paragraph {
    margin-top: 1.5rem;
}
