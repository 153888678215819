@import '../styles/theme.scss';

.progress-breadcrumbs,
.progress-breadcrumbs::after,
.progress-breadcrumbs::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.progress-breadcrumbs.container {
    display: inline;
    margin: 24px auto;
    min-width: 680px;
}

.progress-breadcrumbs.container .circle,
.progress-breadcrumbs.container .circle-connector {
    background-color: $secondary-color-light;
    display: inline-block;
    text-align: center;
}

.progress-breadcrumbs.container .circle {
    border-radius: 48px;
    height: 48px;
    position: relative;
    width: 48px;
    z-index: 1;
}

.progress-breadcrumbs.container .circle-connector {
    height: 6px;
    margin-left: -5px;
    margin-right: -5px;
    position: relative;
    top: -36px;
    width: 120px;
    z-index: 0;
}

.progress-breadcrumbs.container .circle .circle-label {
    border-radius: 32px;
    color: #fff;
    display: inline-block;
    font-size: 17px;
    height: 40px;
    line-height: 40px;
    margin-top: 3px;
    width: 40px;
}

.progress-breadcrumbs.container .circle .circle-title {
    display: inline-block;
    font-size: 13px;
    line-height: 30px;
    margin-left: -28px;
    text-align: center;
    width: 108px;
}

.progress-breadcrumbs.container .circle.active,
.progress-breadcrumbs.container .circle-connector.active {
    background-color: $grey-color;
}

.progress-breadcrumbs.container .circle.active .circle-title {
    font-weight: 700;
}
