@import '../../styles/theme.scss';
@import '../../styles/shared.scss';

.view-icon-circle .user {
    color: white;
    font-size: 36px;
    height: 48px;
    left: 8px;
    position: relative;
    top: 12px;
    width: 48px;
    z-index: 2;
}

.view-icon-circle .check {
    color: $secondary-color;
    font-size: 48px;
    height: 48px;
    left: 18px;
    position: relative;
    top: -23px;
    width: 48px;
    z-index: 2;
}

.postcode-input {
    max-width: 128px;
}

.text-blue {
    color: $primary-color;
}

.text-orange {
    color: $secondary-color;
}
