@import './styles/theme.scss';

.app-container {
    min-height: calc(100vh - 280px);
    padding: 48px;
    display: flex;
    justify-content: center;
    overflow: auto;

    @media screen and (max-width: $small) {
        padding-left: 0;
        padding-right: 0;
    }
}
