@import '../styles/theme.scss';

.banner {
    background-color: $sgi-green-color;
    box-shadow: 10px 10px 10px #ccc;
    color: $secondary-color-light;
    display: flex;
    align-items: center;
    align-self: start;
    justify-content: center;
    padding: 20px 48px;
}

.banner-text {
    color: $secondary-color-light;
    font-size: x-large;
    margin-top: 8px;
}

.banner-embedded-image {
    margin-left: 6px;
    margin-top: 4px;
}

.banner-after-image {
    margin-left: 8px;
}
