@import './styles/theme.scss';

.main-page-container {
    display: block;
}

.main-page-progress-container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
}

.main-page-content-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    width: 100%;
}

.main-page-loading-container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.main-page-loading {
    color: $primary-color;
    font-size: 48px;
}
